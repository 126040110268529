/* reset */
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e5e5e5;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999999;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff !important;
}

.font-weight-600 {
  font-weight: 600;
}
.font-weight-bold {
  font-weight: bold;
}
.ql-editor {
  min-height: 400px;
}

.mainCard {
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 16px;
  margin: 15px 15px 15px 0px;
  padding: 0px 10px 0px 10px;
  background-color: #fff;
}

/* .mainCard:hover {
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #87d068;
} */

.cardCustoms {
  padding: 10px;
}

.walletCard {
  font-size: xx-large;
  color: cornflowerblue;
  margin-bottom: 0;
}

.walletCardP {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.cardCustoms h2 {
  margin-bottom: -2px;
}

.itemP1,
.itemP2 {
  font-size: 14px;
  margin-bottom: 0px;
}

code {
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  color: rgba(#000, 0.6);
  border-radius: 0.5rem;
  padding: 0.2rem;
}

span[data-type="mention"] {
  background-color: #7289da;
  color: white;
  border-radius: 0.4rem;
  box-decoration-break: clone;
  padding: 0.1rem 0.3rem;
  transition:
    background-color 0.5s ease,
    color 0.5s ease;
}

span[data-type="mention"]:hover {
  background-color: #5a67a7;
  color: #e0e0e0;
}

blockquote {
  padding-left: 1rem;
  border-left: 3px solid rgba(13, 13, 13, 0.1);
}
/*Invoice details css */

.header-content h1 {
  color: #000; /* Dark text for visibility */
  margin-bottom: 16px;
}

.invoice-header strong {
  display: block; /* Make labels block elements */
  color: #595959; /* Dark gray for text */
}

.invoice-header p {
  margin: 0;
}

.invoice-header {
  background-color: #e6f7ff !important; /* Light green background */
  padding: 20px;
}

.invoice-header h1 {
  margin-bottom: 16px; /* Space under the company name */
}

.header-content p,
.header-details p {
  margin-bottom: 4px; /* Tighter spacing between lines */
}
.header-details {
  margin-top: 20px;
}
.header-content strong,
.header-details strong {
  min-width: 120px; /* Ensures alignment */
  display: inline-block; /* Aligns label and value on the same line */
}

.header-content span,
.header-details span {
  margin-left: 5px; /* Space after the colon for values */
}
.invoice-card {
  border-radius: 0px !important;
}

#shipment_product_items {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse; /* Add this to ensure borders collapse as expected */
}

#shipment_product_items th,
#shipment_product_items td {
  border-bottom: 1px solid #ddd;
  padding: 16px;
  text-align: left;
}

#shipment_product_items th {
  padding-top: 12px;
  padding-bottom: 12px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

#shipment_product_items td {
  border-top: none;
  border-left: none;
  border-right: none;
}

#shipment_product_items td:last-child {
  text-align: right;
}

#shipment_product_items .border-bottom-style td {
  border-bottom: none !important;
  border-top: 3px solid #000 !important;
}
#shipment_product_items .border-top-style td {
  border-top: 2px solid #444242 !important;
}

#totalPrice {
  text-align: right;
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
}
.align-item-center {
  text-align: center !important;
}
.transactions-container {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header h2 {
  margin: 0;
}

.status {
  display: flex;
  align-items: center;
}

.status span {
  margin-right: 10px;
}

.status-settled {
  background-color: #28a745;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
}

#transactions_table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

#transactions_table th,
#transactions_table td {
  padding: 12px;
  text-align: left;
}

#transactions_table thead {
  background-color: #6c757d;
  color: #fff;
}

#transactions_table th {
  font-weight: bold;
}

#transactions_table tbody tr {
  background-color: #fff;
}

#transactions_table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.buttons {
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
  gap: 10px;
}

.buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: black;
}

.buttons button:hover {
  opacity: 0.8;
}

.buttons button {
  background-color: white; /* Matching the table header background */
  border: 1px solid gray;
}

.border-none {
  border: none !important;
}

.action-button {
  margin-bottom: 20px;
  margin-right: 10px;
}

.invoice-header-brand-bg {
  width: 100%;
  height: 15px;
  background: #28a745;
}

.product-detail-item {
  padding: 5px 0px;
}

.ant-card-head {
  background: #00B650 !important;
  font-weight: bold !important;
  color: white !important;
}
button.ant-btn.ant-btn-primary,
button.ant-btn.ant-btn-primary:hover,
button.ant-btn.ant-btn-primary:focus,
button.ant-btn.ant-btn-primary:active {
  background-color: #00893c !important;
  border-color: #00893c !important; /* Optionally adjust border color */
  color: #fff !important; /* Optionally adjust text color */
}
.ant-menu-item-selected{
  color: black !important;
  background: #dfffed !important;
}
.ant-menu-submenu-selected, .ant-menu-submenu-active{}
.ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title{
  color:#00893c !important;
}
.ant-layout-header {
  /*background:#DFFFED !important;*/
}

/*responsive table*/

.responsive-table-container {
  overflow-x: auto;
}

@media screen and (min-width: 768px) {
  .responsive-table-container {
    overflow-x: hidden;
  }
}

.table-actions {
  display: flex;
  justify-content: flex-end; /* Aligns content to the right */
  flex-direction: row;
}

/* Ensuring the actions are visible and positioned correctly on smaller screens */
@media screen and (max-width: 767px) {
  .table-actions {
    justify-content: space-between;
  }
}

/*global*/
.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.css-dev-only-do-not-override-1kcad56
  li:last-child {
  margin-bottom: 50px !important;
}

.ant-select-disabled{
  opacity: .5;
}
